import BillService from '@/libs/upload-bill.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		uploadBill(ctx, billData) {
			return new Promise((resolve, reject) => {
				BillService.uploadBill(billData)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		}
	}
}
