import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uploadBillStoreModule from '@/views/payments/upload-bill/uploadBillStoreModule'
import store from '@/store'

export default function useUploadBill() {
	const toast = useToast()
	if (!store.hasModule('payment-upload-bill')) store.registerModule('payment-upload-bill', uploadBillStoreModule)
	onUnmounted(() => {
		if (store.hasModule('payment-upload-bill')) store.unregisterModule('payment-upload-bill')
	})
	const uploadBill = (billData) => {
		store.dispatch('payment-upload-bill/uploadBill', billData).then(
				response => {
					if (response.status == 200) {
						if (response.data.code == '00') {
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
						} else {
							toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				}
			)
	}
	return {
		uploadBill,
	}
}
