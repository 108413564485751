/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class BillService {

    uploadBill(billData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('ref', billData.ref)
		if (billData.attachments.length > 0) {
			for (let i = 0; i < billData.attachments.length; i++) {
				data.append('bills[]', billData.attachments[i])
			}
		}
		return instance.post('/api/bill/create', data, { headers: authHeader() })
	}
}

export default new BillService()
