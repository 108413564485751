<template>
	<div>
		<b-modal
			id="modal-approve-withdrawal"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			:ok-variant="okVariant"
			centered
			:title="title"
			:modal-class="modalClass"
			scrollable
			@ok="confirmApprove"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormApprove)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Transaction Id -->
						<b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Transaction id')"
								label-for="transactionId"
							>
								<b-form-input
									id="name"
									v-model="withdrawal.reference"
									trim
									disabled
								/>
							</b-form-group>
						</b-col>

						<!-- Username -->
						<b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Username')"
								label-for="transactionId"
							>
								<b-form-input
									id="name"
									v-model="withdrawal.username"
									trim
									disabled
								/>
							</b-form-group>
						</b-col>

						<!-- Currency -->
						<!-- <b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Currency')"
								label-for="currency"
							>
								<b-form-input
									id="currency"
									v-model="withdrawal.currency"
									disabled
								/>
							</b-form-group>
						</b-col> -->

						<!-- Amount -->
						<b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Amount')"
								label-for="amount"
							>
								<b-form-input
									id="amount"
									v-model="withdrawal.amount"
									disabled
								/>
							</b-form-group>
						</b-col>
						<!-- Note -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="note"
							>
								<label for="remark"><span class="text-danger"></span> {{ $t('Note') }}</label>
								<validation-provider
									#default="{ errors }"
									name="note"
								>
									<b-form-textarea
										id="note"
										v-model="withdrawal.rejectReason"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<!-- Bill -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="attachments"
							>
								<label for="">{{ $t('Bills') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="attachments"
									rules="size:10240"
								>
									<b-form-file
										v-model="attachments"
										multiple
										input-id="attachments"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
								
							</b-form-group>
							<b-button
								v-show="attachments.length != 0"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="attachments = []"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormFile, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { numberFormat, resolveCurrency } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import tagStoreModule from '@/views/apps/user/tag/tagStoreModule'
import useUploadBill from '@/views/payments/upload-bill/useUploadBill'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge, BFormFile,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		withdrawalData: {
			type: [Object, null],
			default: null,
		},
		type: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			required,
			title: '',
			modalClass: '',
			okVariant: '',
			withdrawal: {
				withdrawalId: null,
				reference: null,
				username: null,
				currency: null,
				amount: null,
				status: this.type,
				rejectReason: null,
			},
			attachments: [],
		}
	},
	watch: {
		withdrawalData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.withdrawal = {
						withdrawalId: newVal.id,
						reference: newVal.ref,
						username: newVal.user ? newVal.user.username : '-',
						currency: newVal.currency || 'VND',
						amount: newVal.amount / 1000,
						reviewAmount: numberFormat(newVal.amount / 1000),
						status: 2,
						rejectReason: '',
					}
				}
			}
		},
		type: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					if (newVal == 4) {
						this.modalClass = 'modal-success'
						this.okVariant = 'success'
						this.title = 'Confirm withdrawal transaction'
					} else if (newVal == 7) {
						this.modalClass = 'modal-primary'
						this.okVariant = 'primary'
						this.title = 'Approve withdrawal transaction'
					} else if (newVal == 6) {
						this.modalClass = 'modal-danger'
						this.okVariant = 'danger'
						this.title = 'Reject withdrawal transaction'
					}
					this.withdrawal.status = newVal
				}
			}
		},
	},
	methods: {
		validationFormApprove() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store
						.dispatch('payment-withdrawal/updateWithdrawalStatus', this.withdrawal)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									if (this.attachments.length > 0) {
										this.uploadBill({
											ref: this.withdrawal.reference,
											attachments: this.attachments
										})
									}
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-approve-withdrawal')
											this.resetForm()
										}, '500')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Proccess',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirmApprove(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormApprove()
		},
		resetForm() {
			this.$emit('update:withdrawalData', null)
			this.$emit('update:type', null)
			this.attachments = []
		},
	},
	setup() {
		const { uploadBill } = useUploadBill()
		return {
			uploadBill
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: 100% !important;
}
</style>
